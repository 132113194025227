import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const ContactPage = () => (
  <Layout bodyClassName='contact'>
    <SEO 
      title="Contact"
      description="Here's how to say hello" 
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Get in touch</h1>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">How to reach us</h2>

          <div className="column">
            <h3 className="listTitle">📞 Call</h3>
            <a href="tel:1-513-818-2190">+1 (513) 818-2190</a>
          </div>
          <div className="column">
            <h3 className="listTitle">💬 Text</h3>
            <a href="sms:1-513-818-2190">+1 (513) 818-2190</a>
          </div>
          <div className="column">
            <h3 className="listTitle">✉️ Email</h3>
            <a href="mailto:nick@harper.dog?subject=%E2%9C%8B%20Harper%20help&body=Hey%20Harper%2C%20I%20could%20use%20a%20hand.%20I%20wanted%20to%20let%20you%20know...%0D%0A">nick@harper.dog</a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage
